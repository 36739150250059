import React from 'react'
import { Link } from 'react-router-dom'

export const SoporteInfraestructuraIT = () => {
  return (
  <>
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                <h2>Soporte Integral de Infraestructura IT</h2>
                </div>

                <div className="text">
                  <p>
                  Diseñamos soluciones de soporte técnico a medida de cada empresa, optimizando sus procesos productivos y reduciendo costos.
                  Nos encargamos del cuidado de la tecnología e información de tu negocio, para que puedas enfocarte en tu principal objetivo: <b>hacer crecer tu empresa.</b>
                  <ul className="list-style-two">
                  <li>Diseñamos la infraestructura de red. </li>
                  <li>Realizamos el cableado. </li>
                  <li>
                    Configuramos, instalamos y administramos firewalls, router, switches, servidores de dominio, de archivos, de impresión y de acceso remoto.
                    Brindamos asistencia y soporte técnico con capacidad de detectar, diagnosticar, reparar y/o derivar todo tipo de cuestiones relacionadas con la informática de la empresa
                  </li>
                  </ul>
                  </p>
                </div>

                <div className="btn-box">
                <Link to="/contacto" className="theme-btn btn-style-two">Contacto!</Link>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <a href="/" className="lightbox-image" data-fancybox="images">
                    <img
                      src={"/imagenes/imagenes-productos/servidores.jpg"}
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
  </>
  )
}
