import React from 'react'
import "./PreNavbarStyle.css"

export const PreNavbar = () => {
  return (
    <div className='preNav'>
        <p><img src={"imagenes/imagenes-logo/mail.gif"} alt="Logo mail" className='mr-2'/>info@dvg.com.ar</p>
        <p><img src={"imagenes/imagenes-logo/telefono.gif"} alt="Logo telefono" className='mr-2'/>+5411 4717 3426 - Lineas Rotativas</p>
    </div>
  )
}
