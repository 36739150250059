import React from 'react'
import "./TextoInicioStyle.css"

export const TextoInicio = () => {
  return (
    <div className='container'>
        <p className='parrafo2'>
        <h5 className='subtitulo2'>Nuestros servicios</h5> abarcan todo lo necesario en informática para empresas, diseñamos la infraestructura de red. Realizamos el cableado.<br/> Proveemos , configuramos, instalamos y administramos firewalls, router, switches, servidores de dominio, de archivos, de impresión y de acceso remoto.<br/>
        Además, brindamos asistencia y soporte técnico con capacidad de detectar, diagnosticar, reparar y/o derivar todo tipo de cuestiones relacionadas con la informática de la empresa. <br/>
        <h5 className='subtitulo2'>Tenemos experiencia</h5> en el trabajo en migraciones de redes; mudanzas de empresas; adecuación de redes que han crecido en forma desordenada; liderar proyectos de integración de software; implementaciones de nuevas oficinas; conexión de oficinas remotas y todos aquellos proyectos que demandan las empresas en la actualidad. <br/>
        <h5 className='subtitulo2'>Diseñamos</h5> esquemas de contingencia y backups, asegurando que la red de datos de la empresa tenga la mayor disponibilidad posible acorde a su presupuesto y a la criticidad de los servicios.<br/>
        </p>
    </div>
  )
}
