import React from 'react'
import "./SobreNosotrosStyle.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom'

export const SobreNosotros = () => {
  return (
    <>
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">

                <AnimationOnScroll animateIn="animate__backInUp">
                <div className="sec-title">
                  <span className="title">Sobre Nosotros!</span>
                  <h2>
                    Somos lideres <br />
                    en la industria, desde el año 1999
                  </h2>
                </div>
                </AnimationOnScroll>

                <div className="text">
                <h5><b>Misión</b></h5>
                <ul className="list-style-one">
                  <li>Estamos comprometidos con nuestros clientes a realizar una gestión informática integral. Nuestro objetivo es proveer soluciones que se adapten a los requerimientos y presupuestos de nuestros clientes con los beneficios de lo último en tecnología, compartiendo activamente nuestra experiencia en el sector informático.</li>
                </ul>

                <h5><b>Visión</b></h5>
                <ul className="list-style-one">
                  <li>Buscamos ser un referente confiable, constante y de la mejor calidad para nuestros clientes. Trabajamos con compromiso, respondiendo a las necesidades de las PyMEs y empresas. Nuestra prioridad es diseñar y administrar infraestructuras informáticas estables y seguras, junto con una consultoría que apunte a lo infalible.</li>
                </ul>

                <h5><b>Valores</b></h5>
                <ul className="list-style-one">
                  <li>Tenemos presentes los aspectos humanos y aspiramos a avanzar hacia la sustentabilidad, cultivar la inclusión, mantener la ética y respetar la privacidad. Fomentamos en nuestro personal el compromiso y adaptabilidad en su desempeño
                  Buscamos una atención personalizada y duradera. El vínculo con el cliente es de extrema relevancia; queremos transmitir confianza entendiendo las necesidades del negocio,  poder brindar apoyo a quien lo necesite.</li>
                </ul>


                </div>

                <div className="btn-box">
                  <a href="/contacto" className="theme-btn btn-style-one">
                    Contacto!
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <a href="/" className="lightbox-image" data-fancybox="images">
                    <img
                      src={"/imagenes/servicios/proyectoNuevo.png"}
                      alt=""
                    />
                  </a>
                </figure>
                <figure className="image-2">
                  <a href="/" className="lightbox-image" data-fancybox="images">
                    <img
                        src={"/imagenes/servicios/corporativos.png"}
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
