import React from 'react';
import { FlexMarcas } from '../FlexMarcas/FlexMarcas';
import { Carrousel } from '../Carrousel/Carrousel';
import { ServiciosCard } from '../ServiciosCard/ServiciosCard';
import { BannerBodySoporte } from '../BannerBodySoporte/BannerBodySoporte';
import FlexFeatures from '../FlexFeatures/FlexFeatures';
import { InfoHome } from '../InfoHome/InfoHome';
import { Bienvenida } from '../Bienvenida/Bienvenida';
import { TextoInicio } from "../TextoInicio/TextoInicio"

export const HomePage = () => {
  return (
    <>
        <Carrousel/>
        <Bienvenida/>
        <TextoInicio/>
        {/* <FlexFeatures/> */}
        <ServiciosCard/>
        <InfoHome/>
        <BannerBodySoporte/>
        <FlexMarcas/>
    </>
  )
}
