import React from 'react'
import "./BienvenidaStyle.css"
import { Link } from 'react-router-dom'
import { AnimationOnScroll } from 'react-animation-on-scroll';


export const Bienvenida = () => {
  return (
    <div className='titulosPrincipales'>
    <AnimationOnScroll animateIn="animate__bounceInUp">
        <h2 className='titulo mt-3'>Conozca la propuesta de DVG</h2>
            <div>
                <Link to="/contacto" className='buttonHomeP'>
                    <span>Contactenos!</span>
                </Link>
            </div>
    </AnimationOnScroll>
    <Link to="/contacto"><img src={"/imagenes/banner/bannerMobileBienvenida.jpg"} alt="Imagen banner"></img></Link>
    </div>

)
}
