import React from 'react'
import { Link } from 'react-router-dom'

export const ServidoresFisicos = () => {
  return (
  <>
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                <h2>Servidores Fisicos</h2>
                </div>

                <div className="text">
                  <p>
                  Provision, Administración y Mantenimiento de servidores para empresas.
                    <ul className="list-style-two">
                      <li>Instalamos, configuramos y realizamos la administración de los distintos tipos de servidores que integran la infraestructura IT de su empresa.</li>
                      <li>Le proveemos herramientas, servicios personalizables y especialistas certificados con un conocimiento profundo de los estándares específicos de la industria. Monitoreamos, mantenemos y administramos todos los aspectos críticos de su infraestructura de IT.</li>
                      <li>Protega sus datos en todo momento con un servidor que, además, ayude sus empleados a volverse más productivos y llevar al crecimiento de su empresa.</li>
                      <li>Analizamos de forma permanente el rendimiento de los servidores, centralizando recursos y previniendo cualquier falla que ponga en riesgo los datos de la empresa, asegurando la productividad y evitando pérdidas económicas.</li>
                    </ul>
                  </p>
                </div>

                <div className="btn-box">
                <Link to="/contacto" className="theme-btn btn-style-two">Contacto!</Link>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <a href="/" className="lightbox-image" data-fancybox="images">
                    <img
                      src={"/imagenes/imagenes-productos/servidores.jpg"}
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
  </>
  )
}
