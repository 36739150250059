import Barra1 from './components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { HomePage } from './components/HomePage/HomePage';
import { HomeFooter } from './components/HomeFooter/HomeFooter';
import { Contacto } from './components/pages/Contacto/Contacto';
import { PageNotFound } from './components/pages/PageNotFound/PageNotFound';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import { PreNavbar } from './components/PreNavbar/PreNavbar';
import { Consultoria } from './components/pages/Servicios/Consultoria/Consultoria';
import { SoporteCorporativo } from './components/pages/Servicios/SoporteCorporativo/SoporteCorporativo';
import { VirtualizacionDeServidores } from './components/pages/Servicios/VirtualizacionDeServidores/VirtualizacionDeServidores';
import { SobreNosotros } from './components/pages/SobreNosotros/SobreNosotros';
import { Servicios } from './components/pages/Servicios/Servicios/Servicios';
import { ServidoresFisicos } from './components/pages/Servicios/ServidoresFisicos/ServidoresFisicos';
import { Redes } from './components/pages/Servicios/Redes/Redes';
import { SoporteInfraestructuraIT } from './components/pages/Servicios/SoporteInfraestructuraIT/SoporteInfraestructuraIT';

function App() {
  return (

    <BrowserRouter>
    <ScrollToTop>
        <PreNavbar/>
        <Barra1/>
            <Routes>
              <Route path="/" element= {<HomePage/> }/>
              <Route path='*' element= { <Navigate to ="/PageNotFound" /> } />
              <Route path="/PageNotFound" element= {<PageNotFound/> }/>
              <Route path="/nosotros" element= {<SobreNosotros/> }/>
              <Route path="/contacto" element= {<Contacto/> }/>
              <Route path="/consultoria" element= {<Consultoria/> }/>
              <Route path="/redes" element= {<Redes/> }/>
              <Route path="/servidoresFisicos" element= {<ServidoresFisicos/> }/>
              <Route path="/soporteCorporativo" element= {<SoporteCorporativo/> }/>
              <Route path="/soporteInfraestructura" element= {<SoporteInfraestructuraIT/> }/>
              <Route path="/virtualizacionServidores" element= {<VirtualizacionDeServidores/> }/>
              <Route path="/servicios" element= {<Servicios/> }/>
            </Routes>
        <HomeFooter/>
        </ScrollToTop>
    </BrowserRouter> 
  );
}

export default App;
